export default {
    "title": "🇨🇳 中文簡體",

    "首页": "首頁",
    "关于": "關於",
    "会员": "會員",
    "推荐计划": "推薦計畫",
    "个人": "個人",
    "推荐": "推薦",
    "预订": "預訂",
    "事件": "事件",
    "登出": "登出",
    "登录": "登入",
    "注册": "注册",
    "我们的应用程序现在可用": "我們的應用程序現在可用",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "為了獲得更好的體驗，我們剛剛推出了移動應用程序，以實現無縫、更快的預訂。",
    "iOS版下载（即将推出）": "iOS版下載（即將推出）",
    "Android版下载（即将推出）": "Android版下載（即將推出）",
    "为什么选择我们": "為什麼選擇我們",
    "我们卓越的服务。": "我們卓越的服務。",
    "收入最高的酒店": "收入最高的飯店",
    "我们的合作酒店收入最高。": "我們的合作飯店收入最高。",
    "回报": "回報",
    "最具特色的酒店": "最具特色的飯店",
    "我们平台上最有特色的酒店。": "我們平臺上最有特色的飯店。",
    "热门国家和目的地": "熱門國家和目的地",
    "从我们的合作伙伴和朋友那里了解一些来自城市各地的最佳提示。": "從我們的合作夥伴和朋友那裡瞭解一些來自都市各地的最佳提示。",
    "酒店": "飯店",
    "预订酒店并开始赚取": "預訂酒店並開始賺取",
    "让我们开始与TitanTraveler一起探索世界": "讓我們開始與TitanTraveler一起探索世界",
    "客户评价": "客戶評價",
    "查看我们用户的真实评价。": "查看我們用戶的真實評估。",
    "银行卡列表": "銀行卡清單",
    "银行列表": "銀行清單",
    "持卡人姓名": "持卡人姓名",
    "银行卡号": "銀行卡號",
    "支付密码": "支付密碼",
    "提交": "提交",
    "Pix列表": "Pix清單",
    "Pix类型": "Pix類型",
    "Pix账号": "Pix帳號",
    "账号列表": "帳號清單",
    "网络": "網絡",
    "地址": "地址",
    "备注": "備註",
    "充值金额": "充值金額",
    "支付类型": "支付類型",
    "支付银行": "支付銀行",
    "USDT地址": "USDT地址",
    "二维码": "二維碼",
    "充值渠道": "充值通路",
    "充值凭证": "充值憑證",
    "立即登录": "立即登入",
    "用户名": "用戶名",
    "密码": "密碼",
    "邀请码": "邀請碼",
    "确认密码": "確認密碼",
    "手机": "手機",
    "注册2": "注册",
    "金额": "金額",
    "新增": "新增",
    "我们的精彩": "我們的精彩",
    "故事": "故事",
    "奖项": "獎項",
    "获得": "獲得",
    "钱包余额": "錢包餘額",
    "今日奖励": "今日獎勵",
    "总行程": "總行程",
    "访问行程": "訪問行程",
    "酒店历史": "飯店歷史",
    "单价": "單價",
    "数量": "數量",
    "总额": "總額",
    "奖励": "獎勵",
    "上一页": "上一頁",
    "下一页": "下一頁",
    "每套预订量": "每套預訂量",
    "预订奖励": "預訂獎勵",
    "每日预订": "每日預訂",
    "手机号码": "手機號碼",
    "修改密码": "修改密碼",
    "登录密码": "登入密碼",
    "当前密码": "當前密碼",
    "新密码": "新密碼",
    "确认新密码": "確認新密碼",
    "保存修改": "保存修改",
    "充值": "充值",
    "我们的": "我們的",
    "复制": "複製",
    "分享链接": "分享連結",
    "搜索酒店": "蒐索飯店",
    "实际等级": "實際等級",
    "升级": "陞級",
    "已完成预订": "已完成預訂",
    "登录时间": "登入時間",
    "信誉分": "信譽分",
    "控制菜单": "控制選單",
    "提现": "提現",
    "提现账号": "提現帳號",
    "你确定要删除此账号吗": "你確定要删除此帳號嗎",
    "你确定要删除此地址吗": "你確定要删除此地址嗎",
    "提示": "提示",
    "是": "是",
    "否": "否",
    "全部": "全部",
    "待处理": "待處理",
    "冻结中": "凍結中",
    "已完成": "已完成",
    "订阅": "訂閱",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "希望在我們推出新範本或更新時收到通知。 只需注册，我們就會通過電子郵件向您發送通知。",
    "我们的伙伴": "我們的夥伴",
    "关于我们": "關於我們",
    "实时聊天支持": "即時聊天支援",
    "在线聊天": "線上聊天",
    "创建时间": "創建時間",
    "你确定要登出当前账号吗？": "你確定要登出當前帳號嗎？",
    "请输入你的邮箱": "請輸入你的郵箱",
    "好评": "好評",
    "条评论": "則評論",
    "平均每晚": "平均每晚",
    "旅行旅程": "旅行旅程",
    "正在寻找酒店": "正在尋找酒店",
    "开始预订": "開始預訂",
    "旅程价格": "旅程價格",
    "佣金": "佣金",
    "底部-about": "是一個聯盟計劃平台，為您提供獨特的機會，將您的企業連接到世界上最受認可的線上住宿預訂平台，讓用戶透過該平台進行的每次預訂均可賺取優惠佣金。",
    "立即预订": "立即預訂",
    "搜索": "搜尋",
    "快乐的客户": "快樂的客戶",
    "快乐的酒店老板": "快樂的旅館老闆",
    "评论1": "令人驚嘆的平台，功能強大，確實值得探索！",
    "评论2": "如果您對該平台不清楚，主動的客戶服務將指導您。",
    "评论3": "值得信賴的平台，值得一試！",
    "评论4": "設法在這裡列出我的酒店並取得良好的營銷效果。",
    "推荐计划1": "平台用戶可以透過推薦碼邀請其他人成為平台代理商，成為您的下線。",
    "推荐计划2": "作為上線，您可以從下線的利潤中抽取一定比例，上線獲得的利潤將直接返還至上線的平台帳戶，或返還至團隊報表。",
    "推荐计划3": "註：平台所有代理商/下線都會相應獲得平台一定比例的利潤和獎勵，組成團隊不影響任何代理商或下線的利潤和獎勵。",
}